import { useStaticQuery, graphql } from "gatsby"

export const useAirTrackAssets = () => {
  const images = useStaticQuery(
    graphql`
      query AirTrackImages {
        flipunitBg: file(relativePath: { eq: "airtrack-hero-video.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        flipunitVideo: file(relativePath: { eq: "airtrack_bg.mp4" }) {
          publicURL
        }
        flipLeftBg: file(relativePath: { eq: "flip+padding@2x-8.png" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        flipCornerBg: file(relativePath: { eq: "bg-img2@3x.png" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        flipTeamBg: file(relativePath: { eq: "bg-s3@2x-80.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG])
          }
        }
        watchImage: file(relativePath: { eq: "img-see-the-show.png" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        watchImageHover: file(
          relativePath: { eq: "img-see-the-show__hoover.png" }
        ) {
          src: childImageSharp {
            gatsbyImageData(formats: [PNG])
          }
        }
        teamImage: file(relativePath: { eq: "img-gallery@2x-80.jpg" }) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG], layout: FULL_WIDTH)
          }
        }
        teamImageHover: file(
          relativePath: { eq: "img-gallery__hover@2x-80.jpg" }
        ) {
          src: childImageSharp {
            gatsbyImageData(formats: [JPG], layout: FULL_WIDTH)
          }
        }
        gallery: allFile(
          filter: {
            absolutePath: { regex: "/images/airtrack/" }
            extension: { ne: "mp4" }
          }
          sort: { fields: name }
        ) {
          edges {
            node {
              src: childImageSharp {
                gatsbyImageData(formats: [JPG], width: 1920)
              }
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  return {
    flipunitBg: images.flipunitBg.src.gatsbyImageData.images.fallback.src,
    flipunitVideo: images.flipunitVideo.publicURL,
    flipLeftBg: images.flipLeftBg.src.gatsbyImageData.images.fallback.src,
    flipCornerBg: images.flipCornerBg.src.gatsbyImageData.images.fallback.src,
    flipTeamBg: images.flipTeamBg.src.gatsbyImageData.images.fallback.src,
    watchImage: images.watchImage.src.gatsbyImageData.images.fallback.src,
    watchImageHover:
      images.watchImageHover.src.gatsbyImageData.images.fallback.src,
    teamImage: images.teamImage.src.gatsbyImageData.images.fallback.src,
    teamImageHover:
      images.teamImageHover.src.gatsbyImageData.images.fallback.src,
    gallery: images.gallery.edges.map((e, idx) => ({
      photo: `${images.site.siteMetadata.siteUrl}${e.node.src.gatsbyImageData.images.fallback.src}`,
      number: idx,
      thumbnail: `${images.site.siteMetadata.siteUrl}${e.node.src.gatsbyImageData.images.fallback.src}`,
    })),
  }
}
