import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import ReactBnbGallery from "react-bnb-gallery"
import styled from "styled-components/macro"

import Container from "../components/common/Container"
import ContactForm from "../components/ContactForm"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useAirTrackAssets } from "../hooks/airtrack"
import LogoIcon from "../images/logo-icon.svg"
import Unit from "../images/title-asset.svg"
import { pxToRem } from "../utils"

const Hero = styled.section`
  position: relative;
  height: calc(100vh - 85px);
  text-align: center;

  & ${Container} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: ${pxToRem(70)};

    @media all and (min-width: 1281px) {
      padding-bottom: ${pxToRem(100)};
    }
  }
`
const HeroTitle = styled.h1`
  font-family: "Teko", sans-serif;
  font-size: ${pxToRem(80)};
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${pxToRem(20)};
  line-height: 0.85;

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(90)};
  }
  @media all and (min-width: 1281px) {
    font-size: ${pxToRem(110)};
  }
`
const HeroAltTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(30)};
  font-weight: 700;
  margin: 0;
  line-height: 1.2;

  @media all and (min-width: 1281px) {
    font-size: ${pxToRem(40)};
  }
`
const VideoBackground = styled.div`
  height: inherit;
  width: 100%;
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
`
const Video = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`
const Teaser = styled.div`
  position: relative;
  padding: ${pxToRem(60)} 0;
  background: linear-gradient(
    90deg,
    rgba(4, 4, 21, 1) 0%,
    rgba(4, 4, 21, 1) 26.46%,
    rgba(12, 12, 74, 1) 80.42%,
    rgba(0, 0, 0, 1) 100%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#040415',endColorstr='#000000' , GradientType=1);

  & ${Container} {
    max-width: 1200px;

    @media all and (min-width: 1400px) {
      max-width: 1400px;
    }
  }
`
const TeaserLogoIconWrapper = styled.div`
  margin: 0 auto ${pxToRem(100)} auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TeaserLogoIcon = styled(LogoIcon)`
  color: #fff;
  width: 40px;
  display: block;

  & path {
    fill: #fff;
  }
`
const TeaserDescription = styled.p`
  font-weight: 300;
  font-size: ${pxToRem(28)};
  text-align: center;
  padding: 0 ${pxToRem(20)};
  max-width: 1200px;
  margin: 0 auto ${pxToRem(40)} auto;

  @media all and (min-width: 821px) {
    margin: 0 auto ${pxToRem(130)} auto;
  }
`
const TeaserGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media all and (min-width: 821px) {
    grid-template-columns: 1fr 1fr;
  }
`
const TeaserGridDescription = styled.p`
  font-weight: 300;
  font-style: italic;
  font-size: ${pxToRem(28)};
  text-align: center;

  @media all and (min-width: 821px) {
    text-align: left;
    font-size: ${pxToRem(40)};
  }
`
const Strong = styled.span`
  font-weight: 700;
`
const Urban = styled.div`
  padding: ${pxToRem(70)} 0 ${pxToRem(50)} 0;
  background-color: #0c0c4a;
  background-image: ${props => `url(${props.flipCornerBg})`};
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;

  @media all and (min-width: 1024px) {
    padding: ${pxToRem(140)} 0 ${pxToRem(100)} 0;
    background-image: ${props =>
      `url(${props.flipLeftBg}),  url(${props.flipCornerBg})`};
    background-position: left center, top right;
    background-repeat: no-repeat, no-repeat;
    background-size: contain, contain;
  }
`
const UrbanDescription = styled.p`
  font-weight: 300;
  font-size: ${pxToRem(28)};
  text-align: center;
  line-height: 1.5;
  margin-bottom: ${pxToRem(50)};
`
const VideoIframe = styled.iframe`
  width: 100%;
  max-width: 960px;
  display: block;
  border: none;
  height: 225px;
  margin: 0 auto;
  border: 15px solid #fff;

  @media all and (min-width: 1281px) {
    height: 350px;
  }

  @media all and (min-width: 1681px) {
    height: 425px;
  }
`
const Team = styled.section`
  padding: ${pxToRem(70)} 0 ${pxToRem(70)} 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  & ${Container} {
    max-width: 1200px;

    @media all and (min-width: 1400px) {
      max-width: 1400px;
    }
  }

  @media all and (min-width: 1024px) {
    padding: ${pxToRem(130)} 0 ${pxToRem(70)} 0;
  }
`
const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 200px;

  @media all and (min-width: 821px) {
    grid-template-columns: 1fr 1fr;
  }
`
const Column = styled.div`
  position: relative;
`
const ImageColumn = styled(Column)`
  display: none;
  height: 100%;
  justify-content: center;
  align-items: center;

  @media all and (min-width: 821px) {
    display: flex;
  }
`
const TeamIframe = styled(VideoIframe)`
  height: 225px;

  @media all and (min-width: 1025px) {
    height: 350px;
  }

  @media all and (min-width: 1400px) {
    height: 425px;
  }
`
const TeamTitle = styled.h2`
  font-family: "Teko", sans-serif;
  font-size: ${pxToRem(110)};
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${pxToRem(50)};
  line-height: 0.85;
`
const TeamDescription = styled.p`
  font-weight: 300;
  font-style: italic;
  font-size: ${pxToRem(28)};
  line-height: 1.5;
  margin-bottom: ${pxToRem(40)};
`
// const TeamGallery = styled.div`
//   min-width: 345px;
//   width: 100%
//   max-width: 630px;
//   height: 305px;
//   background-color: #fff;
// `
const Watch = styled.section`
  position: relative;
  padding: ${pxToRem(100)} 0 ${pxToRem(180)} 0;
  background: linear-gradient(
    45deg,
    rgba(4, 4, 21, 1) 0%,
    rgba(12, 12, 74, 1) 67.72%,
    rgba(4, 4, 27, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#040415',endColorstr='#04041B' , GradientType=1);
`
// const WatchStrongTitle = styled.span`
//   font-size: ${pxToRem(100)};
//   text-transform: uppercase;
//   line-height: 1;
// `
const WatchTitle = styled.h2`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${pxToRem(60)};
  font-weight: 600;
  font-size: ${pxToRem(60)};
  font-family: "Teko", sans-serif;

  @media all and (min-width: 768px) {
    flex-direction: row;
  }
`
const WatchGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: ${pxToRem(40)};

  @media all and (min-width: 821px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`
const WatchImageHover = styled.a`
  display: block;
  justify-self: center;
  align-self: center;
  min-width: 345px;
  max-width: 700px;
  width: 100%;
  height: 185px;
  background-image: ${props => (props.bg ? `url(${props.bg})` : "")};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-image 0.3s ease-in-out;

  &:hover {
    background-image: ${props => (props.hover ? `url(${props.hover})` : "")};
  }

  @media all and (min-width: 821px) {
    grid-column: 1 / span 2;
    height: 220px;
  }

  @media all and (min-width: 1281px) {
    height: 300px;
  }

  @media all and (min-width: 1681px) {
    height: 335px;
  }
`
const StyledUnit = styled(Unit)`
  max-width: 280px;
  margin: ${pxToRem(20)} 0;

  @media all and (min-width: 768px) {
    margin: ${pxToRem(-15)} ${pxToRem(20)} 0 ${pxToRem(20)};
  }
`
const Contact = styled.section`
  padding: ${pxToRem(100)} 0 ${pxToRem(100)} 0;
  background-color: #000;
  /* background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) -90%,
    rgba(0, 0, 0, 1) 20%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#000000' , GradientType=1); */
`
const ContactTitle = styled.h2`
  font-weight: 600;
  font-size: ${pxToRem(60)};
  font-family: "Teko", sans-serif;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: ${pxToRem(20)};
`
const ContactDescription = styled.p`
  font-weight: 300;
  font-size: ${pxToRem(24)};
  text-align: center;

  @media all and (min-width: 1025px) {
    font-size: ${pxToRem(28)};
    &:last-child {
      margin-bottom: ${pxToRem(60)};
    }
  }
`
const AltTag = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-style: italic;
`
const GalleryImageHover = styled.div`
  display: block;
  justify-self: center;
  align-self: center;
  height: 193px;
  background-image: ${props => (props.bg ? `url(${props.bg})` : "")};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-image 0.3s ease-in-out;

  &:hover {
    background-image: ${props => (props.hover ? `url(${props.hover})` : "")};
  }

  @media all and (min-width: 415px) {
    height: 400px;
  }

  @media all and (min-width: 1024px) {
    height: 230px;
  }

  @media all and (min-width: 1280px) {
    height: 300px;
  }
`

const AirTrackShow = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [startingImageId, setStartingImageId] = useState(0)
  const toggleIsOpen = (id = 0) => {
    setStartingImageId(id)
    setIsOpen(!isOpen)
  }
  const {
    flipunitBg,
    flipunitVideo,
    flipLeftBg,
    flipCornerBg,
    flipTeamBg,
    watchImage,
    watchImageHover,
    teamImage,
    teamImageHover,
    gallery,
  } = useAirTrackAssets()
  return (
    <Layout page="airtrack">
      <Hero>
        <Container>
          <HeroTitle className="wow animate__fadeIn">AirTrack Show</HeroTitle>
          <HeroAltTitle className="wow animate__fadeIn" data-wow-delay=".2s">
            The most powerful entertaining experience at your event!
          </HeroAltTitle>
        </Container>
        <VideoBackground>
          <Video
            autoPlay
            muted
            loop
            playsInline
            poster={flipunitBg}
            preload="metadata"
          >
            <source src={flipunitVideo} type="video/mp4" />
          </Video>
        </VideoBackground>
        <StaticImage
          src="../images/mask-top.png"
          alt="Flip Unit Airtrack Show"
          style={{ position: "absolute", bottom: -2, left: 0, right: 0 }}
        />
      </Hero>
      <Teaser>
        <Container>
          <TeaserLogoIconWrapper className="wow animate__fadeIn">
            <TeaserLogoIcon />
          </TeaserLogoIconWrapper>
          <TeaserDescription
            className="wow animate__fadeIn"
            data-wow-delay=".2s"
          >
            Loud cheering audience, huge double flips, breathtaking and unique
            acrobatic tricks, flipping over crowds and the opportunity to join
            the fun with the help of activity instructors…
          </TeaserDescription>
          <TeaserGrid>
            <TeaserGridDescription className="wow animate__fadeIn">
              The FLIP UNIT’s airtrack show is{" "}
              <Strong>a premium high-end freestyle acrobatics supershow</Strong>{" "}
              especially designed for world-class entertainment events.
            </TeaserGridDescription>
            <StaticImage
              src="../images/flipunit-team.png"
              alt="flipunit-team"
              className="wow animate__fadeIn"
              data-wow-delay=".2s"
            />
          </TeaserGrid>
        </Container>
        <StaticImage
          src="../images/mask-bottom.png"
          alt="Flip Unit Airtrack Show"
          style={{
            position: "absolute",
            bottom: 1,
            left: 0,
            right: 0,
            transform: "translateY(100%)",
          }}
        />
      </Teaser>
      <Urban flipLeftBg={flipLeftBg} flipCornerBg={flipCornerBg}>
        <Container>
          <UrbanDescription className="wow animate__fadeIn">
            This modern urban extreme showcase combines the most spectacular
            elements of freestyle acrobatics, tumbling, parkour and cheerleading
            with an extraordinary <Strong>energetic atmosphere</Strong>. The
            unique act is performed on a state-of-the-art technology inflatable
            airtrack gymnastic mat and can be combined with an all day long try
            out and training for your guests, tutored by our pro athletes.
          </UrbanDescription>
          <TeamIframe
            loading="lazy"
            data-src="https://www.youtube-nocookie.com/embed/YzRywapbJ5A"
            src={`https://www.youtube-nocookie.com/embed/YzRywapbJ5A`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowFullScreen
            className="wow animate__fadeIn lazyload"
            data-wow-delay=".2s"
          />
        </Container>
      </Urban>
      <Team style={{ backgroundImage: `url(${flipTeamBg})` }}>
        <Container>
          <TeamGrid>
            <Column>
              <TeamTitle className="wow animate__fadeIn" data-wow-delay=".2s">
                The Team
              </TeamTitle>
              <TeamDescription
                className="wow animate__fadeIn"
                data-wow-delay=".2s"
              >
                The Unit consists exclusively of professional athletes who each
                have more than 12+ years of experience in acrobatic sport
                disciplines.
              </TeamDescription>
              <TeamDescription
                className="wow animate__fadeIn"
                data-wow-delay=".3s"
              >
                We have done more than 2000 shows in the past 10 years.{" "}
                <Strong>We are always ready for a new challenge!</Strong>
              </TeamDescription>
              <GalleryImageHover
                onClick={() => toggleIsOpen(0)}
                bg={teamImage}
                hover={teamImageHover}
                className="wow animate__fadeIn"
                data-wow-delay=".4s"
              />
              <ReactBnbGallery
                activePhotoIndex={startingImageId}
                show={isOpen}
                photos={gallery}
                onClose={toggleIsOpen}
              />
            </Column>
            <ImageColumn className="wow animate__fadeIn" data-wow-delay=".2s">
              <StaticImage
                src="../images/bg-img-symb@3x.png"
                alt="flipunit logo background"
              />
              <StaticImage
                src="../images/img-s3@3x.png"
                alt="flipunit handstand"
                width={440}
                style={{
                  position: "absolute",
                  bottom: `calc(0px - ${pxToRem(70)})`,
                  left: "-10%",
                  zIndex: 2,
                  width: 440,
                }}
              />
              <StaticImage
                src="../images/img-s3_1@3x.png"
                alt="flipunit blackflip"
                width={740}
                style={{
                  position: "absolute",
                  right: "-25%",
                  zIndex: 1,
                }}
              />
            </ImageColumn>
          </TeamGrid>
        </Container>
      </Team>
      <Watch>
        <Container>
          <WatchTitle className="wow animate__fadeIn">
            Watch the <StyledUnit /> in action!
          </WatchTitle>
          <WatchGrid>
            <WatchImageHover
              href="#contact"
              bg={watchImage}
              hover={watchImageHover}
              className="wow animate__fadeIn"
              data-wow-delay=".2s"
            />
            <VideoIframe
              loading="lazy"
              data-src="https://www.youtube-nocookie.com/embed/ayMimaEe_8A"
              src={`https://www.youtube-nocookie.com/embed/ayMimaEe_8A`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowFullScreen
              style={{ borderWidth: 10 }}
              className="wow animate__fadeInLeft lazyload"
              data-wow-delay=".2s"
            />
            <VideoIframe
              loading="lazy"
              data-src="https://www.youtube-nocookie.com/embed/2TZDHr5a3QQ"
              src={`https://www.youtube-nocookie.com/embed/2TZDHr5a3QQ`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowFullScreen
              style={{ borderWidth: 10 }}
              className="wow animate__fadeInRight lazyload"
              data-wow-delay=".2s"
            />
          </WatchGrid>
        </Container>
        <StaticImage
          src="../images/mask-contact.png"
          alt="Flip Unit Airtrack Show"
          style={{
            position: "absolute",
            bottom: -1,
            left: 0,
            right: 0,
            transform: "scaleX(-1)",
          }}
        />
      </Watch>
      <Contact id="contact">
        <Container>
          <ContactTitle className="wow animate__fadeIn">
            Contact Us!
          </ContactTitle>
          <ContactDescription
            className="wow animate__fadeIn"
            data-wow-delay=".2s"
          >
            We are ready to dazzle your audience, you only have to{" "}
            <Strong>contact us!</Strong>
          </ContactDescription>
          <ContactDescription
            style={{ marginBottom: `${pxToRem(60)}` }}
            className="wow animate__fadeIn"
            data-wow-delay=".3s"
          >
            <Strong>Fill the form</Strong> below or message us at{" "}
            <AltTag href="mailto:show@flipunitprod.com">
              show@flipunitprod.com
            </AltTag>
          </ContactDescription>
          <ContactForm
            formName="Airtrack fix"
            buttonColor="#c5c005"
            video={true}
            webhookURL="https://flipunitprod-en.netlify.app/.netlify/functions/airtrack"
            mailchimpTags={[
              "AirTrack show oldali contact formon iratkozott fel",
            ]}
          />
        </Container>
      </Contact>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="FLIP UNIT AIRTRACK SHOW | Freestyle urban acrobatics show"
    slug="/airtrack-show"
    description="Freestyle acrobatics and gymnastics performed on a huge air mat combined with cheerleading stunts and interactive elements and jumping/flipping over people."
  />
)

export default AirTrackShow
